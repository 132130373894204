import validate from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@8.57.0_rollup@4.21.2_sass@1.79.4_stylelint@14.16.1_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/validate.js";
import netlify_45redirects_45global from "/opt/build/repo/middleware/netlify-redirects.global.ts";
import remove_45trailing_45slash_45global from "/opt/build/repo/middleware/remove-trailing-slash.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@8.57.0_rollup@4.21.2_sass@1.79.4_stylelint@14.16.1_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  netlify_45redirects_45global,
  remove_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}